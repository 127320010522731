.RelatedCategoryAddEditModal {
  .Modal {
    width: 100%;
  }
  .related-categories-add-modal {
    .list-wrapper {
      width: 100%;
      max-height: 400px;
      overflow-y: auto;
    }
  }
  .space-wrapper {
    display: flex;
    align-items: center;
  }
  .space {
    display: flex;
    // border: 1px solid red;
    margin-right: 8px;
    height: 0px;
    border-bottom: 1px dashed black;
  }
}
