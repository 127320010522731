.param-cell {
  // border-radius: 32px;
  // border: 1px solid var(--color-border-lighter);
  // padding: 4px 6px;
  margin-top: -4px;
  margin-bottom: -4px;
  display: flex;
  align-items: center;


  .TextInput {
    textarea,
    input{
      padding: 6px 8px;
      font-size: 1.2rem;
    }
  }
    span.add-btn {
    cursor: pointer;
    padding: 4px;
  }

  position: relative;

  span.lang-btn {
    top: -6px;
    right: -12px;
    cursor: pointer;
    padding: 4px;
    margin-left: 4px;
    position: absolute;
    display: block;
    z-index: 2;
    font-size: 1.4rem;
    &.gray-scale {
      filter: grayscale(1) brightness(1.5);
    }
    .lang-dots {
      color: red;
      display: inline-flex;
      margin-top: -4px;
      top: -3px;
      transform: translateY(-7px);
    }
  }

  &.--with-label{
    span.lang-btn {
      top: 14px;
      right: -12px;
    }
  }
}
