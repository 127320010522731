.error-toast {
  div {
    background: rgb(255, 60, 60);
  }
}

.success-toast {
  div {
    background: rgb(60, 190, 60);
  }
}

div[class^="style_toast-list_"]{
  z-index: 99999999;
}
