.RelatedAuthorAddEditModal {
  .Modal {
    width: 100%;
  }
  .related-authors-add-modal {
    .list-wrapper {
      width: 100%;
      max-height: 400px;
      overflow-y: auto;
    }
  }
}
