.wysiwig-editor {
  padding-top: 6px;
  padding-bottom: 6px;
  position: relative;

  &.hidden-bridge {
    position: absolute;
    z-index: -999999;
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    user-select: none;
  }

  .validation-messages {
    transition-duration: 0.15s;
    display: inline-block;
    transform: translateY(20px);
    color: var(--color-error);
    opacity: 0;

    &.active {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  label {
    display: block;
    padding-bottom: 5px;
    padding-top: 3px;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .wrapper-class {
    width: 100%;
    border-color: var(--color-border-light);
    border-style: solid;
    background: var(--color-background);
    color: var(--color-fonts);
    border-radius: 6px;
    border-width: 1px;
  }
  .rdw-editor-toolbar {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-bottom: 0px;
    border-style: solid;
    background: var(--color-background);
    color: var(--color-fonts);
  }
  .rdw-dropdownoption-active,
  .rdw-dropdown-selectedtext,
  .rdw-dropdown-optionwrapper,
  .rdw-option-wrapper {
    border-color: var(--color-border-light);
    background: var(--color-background);
    color: var(--color-fonts);
  }

  .rdw-option-wrapper {
    border: none;
  }

  .rdw-dropdownoption-active {
    background: var(--color-primary);
  }
  .public-DraftStyleDefault-block {
    min-height: 8px;
  }
  .public-DraftStyleDefault-block {
    margin: 4px 0;
  }
  .rdw-editor {
    min-height: 300px;
  }

  .editor-class {
    padding: 0px 12px 10px 12px;
    width: 100%;
    min-height: 256px;
    font-size: 1.4rem;
    letter-spacing: 0.05em;
    line-height: 1.5;
    cursor: text;
  }

  .editor-class {
    // .public-DraftEditor-content {
    //   opacity: 0.5;
    //   &:focus {
    //     opacity: 1;
    //   }
    // }

    // .rdw-editor-main {
    //   &:focus {
    //     background: red;
    //   }
    //   .public-DraftStyleDefault-block {
    //     opacity: 0.5;
    //   }
    // }
  }

  &.wysiwig-editor--disabled {
    .wrapper-class {
      background: var(--color-bg-panel-60a);
      color: var(--color-fonts-inactive);
      .rdw-editor-toolbar {
        opacity: 0.4;
        background: none;
      }
      * {
        pointer-events: none;
      }
      span,
      input {
        color: var(--color-fonts-inactive) !important;
        &:hover {
          color: var(--color-fonts-inactive) !important;
        }
        cursor: initial;
      }
    }
  }
}

.theme-dark {
  .wysiwig-editor {
    .rdw-dropdown-carettoopen {
      border-top: 6px solid var(--color-fonts);
    }
    .rdw-option-wrapper {
      img {
        filter: invert(1);
      }
    }
  }
}
