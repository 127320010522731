.lang-name-tabs {
  margin-top: -12px;
  margin-bottom: -12px;
  .lang-name-tab {
    border: 1px solid var(--color-border-superlight);
    vertical-align: "text-top";
    border-radius: 4px;
    padding: 0px 4px;
    margin: 2px;
    .emoji-flags {
      border-right: 1px solid var(--color-border-superlight);
      padding-right: 4px;
    }
  }
}

.prod-variant-manager {
  .header-col-disable-scroll {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .badge {
    background: var(--color-border-ultralight);
    display: inline-block;
    border: 1px solid var(--color-border-superlight);
    border-radius: 4px;
  }
  .red-bg {
    background: var(--color-background);
    border-top: 2px solid var(--color-primary);
  }
  .header-options {
    transform: translateX(8px);
    padding: 0px 2px;
    cursor: pointer;
    font-size: 1.3rem;
  }
  .max-width-3 {
    width: calc(50px * 6);
  }
  strong {
    font-weight: 500;
  }
  .list-row-children {
    padding-top: 0px;
    padding-bottom: 0px;

    border-left: 0px dashed var(--color-border-lighter);
    border-bottom: 0px solid var(--color-border-lighter);
    border-top: 0px solid var(--color-border-lighter);

    .list-row {
      margin-top: 0px !important;
      border-top: 0px solid var(--color-border-lighter) !important;

      &.border-top {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-top: 1px solid var(--color-border-lighter) !important;
        // box-shadow: 0px -1px 0px 0px var(--color-border-lighter);
      }
    }

    .params-col {
      .d-flex {
        flex-wrap: wrap;
      }
    }

    .headers-row {
      margin-top: 0px !important;
    }
    .list-row-children {
      .list-row {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
  }

  .color-primary {
    color: var(--color-primary);
  }

  .color-primary-fonts {
    color: var(--color-fonts-primary);
  }

  .params-inputs {
    align-items: flex-end;
    gap: 4px 8px;
    .param-cell {
      .TextInput {
        max-width: 100%;
      }
      label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
