.page-wrapper {
  border-radius: 12px;
  // background: var(--color-background);
  // padding: 64px 0px;

  .container {
    // margin-left: 0px;
  }
  .d-none {
    display: none;
  }

  .mt-05 {
    margin-top: 3px;
  }
}
